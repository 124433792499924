<template>
  <div class="artworkAdd bgc_ffffff width_1200">
    <el-form
      :model="formData"
      :rules="rules"
      ref="formData"
      label-width="100px"
    >
      <el-form-item label="作品名称" prop="GoodsName">
        <el-input
          v-model="formData.GoodsName"
          placeholder="请输入作品名称"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="作品分类" prop="CategoryPropertyName">
            <el-col>
              <el-select
                v-model="formData.CategoryPropertyName"
                placeholder="请选择作品分类"
                @change="onselectCategory"
              >
                <el-option
                  :label="cl.Name"
                  :value="cl.Id"
                  v-for="(cl, i) in categoryList"
                  :key="i"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="创作年代" prop="ArtYearName">
            <el-select
              v-model="formData.ArtYearName"
              placeholder="请选择创作年代"
              @change="onselectArtYear"
            >
              <el-option
                :label="cl.Name"
                :value="cl.Id"
                v-for="(cl, i) in artYearList"
                :key="i"
              ></el-option>
            </el-select> </el-form-item
        ></el-col>
      </el-row>
      <el-form-item label="作品尺寸" prop="ArtSizeName">
        <el-input
          v-model="formData.ArtSizeName"
          placeholder="请输入作品尺寸"
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="材质" prop="ArtMaterial">
            <el-select
              v-model="formData.ArtMaterial"
              placeholder="请选择材质"
              @change="onselectMaterial"
            >
              <el-option
                :label="cl.Name"
                :value="cl.Name"
                v-for="(cl, i) in artMaterialList"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="题材" prop="ArtTheme">
            <el-select
              v-model="formData.ArtTheme"
              placeholder="请选择题材"
              @change="onselectTheme"
            >
              <el-option
                :label="cl.Name"
                :value="cl.Name"
                v-for="(cl, i) in artThemeList"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="技法" prop="ArtSkill">
            <el-select
              v-model="formData.ArtSkill"
              placeholder="请选择技法"
              @change="onselectSkill"
            >
              <el-option
                :label="cl.Name"
                :value="cl.Name"
                v-for="(cl, i) in artSkillList"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="形制" prop="ArtShape">
            <el-select
              v-model="formData.ArtShape"
              placeholder="请选择形制"
              @change="onselectShape"
            >
              <el-option
                :label="cl.Name"
                :value="cl.Name"
                v-for="(cl, i) in artShapeList"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24"></el-col>
      </el-row>
      <el-form-item label="作品系列" prop="WorkSeries">
        <el-input
          v-model="formData.WorkSeries"
          placeholder="请输入作品系列"
        ></el-input>
      </el-form-item>
      <el-form-item label="售出价格" prop="DefaultPrice">
        <el-input
          v-model="formData.DefaultPrice"
          type="number"
          placeholder="请输入售出价格"
        ></el-input>
      </el-form-item>
      <el-form-item label="作品简介" prop="Introduce">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
          v-model="formData.Introduce"
          placeholder="请输入作品简介"
        ></el-input>
      </el-form-item>
      <el-form-item label="作品主图" prop="Introduce">
        <el-upload
          class="avatar-uploader"
          :limit="1"
          :headers="imgHeaders"
          :http-request="afterRead"
          :action="imagesUploadPic1 + 'coverImg'"
          :show-file-list="false"
        >
          <img
            v-if="formData.CoverImg"
            :src="formData.CoverImg"
            class="avatar"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="作品图集" prop="Introduce">
        <el-upload
          multiple
          :headers="imgHeaders"
          :action="imagesUploadPic1 + 'Images'"
          list-type="picture-card"
          :http-request="afterRead"
          :file-list="imgFileList"
        >
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <img
              class="el-upload-list__item-thumbnail"
              :src="file.url"
              alt=""
            />
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(file)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>

    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
import {
  queryArtSize,
  queryArtProperty,
  queryArtYear,
  queryArtMaterial,
  queryArtTheme,
  queryArtSkill,
  queryArtShape,
  artWorkAdd,
  queryArtWorkDetail,
} from "@/api/art";
import { imagesUploadPic } from "@/api/api";
import { Dialog } from "vant";
export default {
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      imagesUploadPic1: "",
      imgHeaders: { "Content-Type": "multipart/form-data" },
      areaList,
      username: "",
      password: "",
      value: "",
      showArtSize: false,
      showArtYear: false,
      showCategory: false,
      showArea: false,
      showArtMaterial: false,
      showArtShape: false,
      showArtSkill: false,
      showArtTheme: false,
      minDate: new Date(1921, 0, 1),
      defaultDate: new Date(1970, 0, 1),
      maxDate: new Date(),
      artSizeList: [],
      artYearList: [],
      categoryList: [],
      artThemeList: [],
      artSkillList: [],
      artShapeList: [],
      artMaterialList: [],
      formData: {
        CategoryProperty: "",
        CategoryPropertyName: "",
        ArtYearId: "",
        ArtYearName: "",
        ArtSizeId: "",
        ArtSizeName: "",
        ArtShape: "",
        ArtSkill: "",
        ArtMaterial: "",
        ArtTheme: "",
        Introduce: "",
        CoverImg: "",
        Images: "",
        SupplierId: "",
        DefaultPrice: 0.0,
      },
      imgFileList: [],
      imgList: [],
      CoverImgFileList: '',
      CoverImgList: [],
      serverUrl: "",
      uploadName: "",
      route: {},
      rules: {
        GoodsName: [
          { required: true, message: '请输入作品名称', trigger: 'blur' },
        ],
        CategoryPropertyName: [
          { required: true, message: '请选择作品分类', trigger: 'change' }
        ],
        // ArtYearName: [
        //   { required: true, message: '请选择创作年代', trigger: 'change' }
        // ],
        // ArtSizeName: [
        //   { required: true, message: '请输入作品尺寸', trigger: 'blur' },
        // ],
        // ArtMaterial: [
        //   { required: true, message: '请选择材质', trigger: 'change' }
        // ],
        // ArtTheme: [
        //   { required: true, message: '请选择题材', trigger: 'change' }
        // ],
        // ArtSkill: [
        //   { required: true, message: '请选择技法', trigger: 'change' }
        // ],
        // ArtShape: [
        //   { required: true, message: '请选择形制', trigger: 'change' }
        // ],
        // WorkSeries: [
        //   { required: true, message: '请输入作品系列', trigger: 'blur' },
        // ],
        // DefaultPrice: [
        //   { required: true, message: '请输入售出价格', trigger: 'blur' },
        // ],
        // Introduce: [
        //   { required: true, message: '请输入作品简介', trigger: 'blur' },
        // ]
      }
    };
  },
  created () {
    this.init();
  },
  mounted () {
    this.route = this.$route.query;
    if (this.route.id) {
      this.queryArtWorkDetail();
    }
  },
  methods: {
    // 删除图片
    handleRemove (file) {
      // console.log(file);
      this.imgFileList = this.imgFileList.filter(element => {
        return element.url != file.url
      });
      this.imgList = this.imgList.filter(element => {
        return element != file.url
      });
      // console.log(this.imgFileList, '')
      // console.log(this.imgList, '')
    },
    // 放大
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    async init () {
      // this.getQueryArtProperty(0);
      this.getQueryArtProperty(2);
      this.queryArtSize();
      this.queryArtYear();
      this.queryArtMaterial();
      this.queryArtTheme();
      this.queryArtSkill();
      this.queryArtShape();
    },
    onselectArtSize (value) {
      this.formData.ArtSizeId = value.Id;
      this.formData.ArtSizeName = value.Name;
      this.showArtSize = false;
    },
    onselectArtYear (value) {
      this.formData.ArtYearId = value;
    },
    onselectCategory (value) {
      this.formData.CategoryProperty = value;
    },
    onselectMaterial (value) {
    },
    onselectTheme (value) {
    },
    onselectSkill (value) {
    },
    onselectShape (value) {
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.onSubmit()
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    async onSubmit () {
      // this.formData.CoverImg = this.CoverImgList.join(",");
      this.formData.Images = this.imgList.join(",");
      this.formData.SupplierId =
        this.formData.Id > 0 ? this.formData.SupplierId : this.route.artistId;
      // console.log(this.formData);

      const res = await artWorkAdd(this.formData);

      if (res.success == true) {
        Dialog.alert({
          message: "作品发布成功",
          theme: "round-button",
          confirmButtonColor: "#ee0a24",
        }).then(() => {
          let url =
            "/art/artistsDetails?id=" +
            res.response +
            "&artUserId=" +
            this.route.UserID;
          this.$router.push(url);
        });
      } else {
        this.$message.error("作品发布失败，请重试");
      }
    },

    formatDate (date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    async queryArtWorkDetail () {
      const res = await queryArtWorkDetail(
        "?id=" + this.route.id + "&studentId=" + this.route.UserID
      );

      if (res.data.response != null) {
        this.formData = res.data.response.ArtDetail;
        this.formData.Introduce = this.formData.Introduce.replace(/<[^>]+>/g, '')
        this.CoverImgList.push(res.data.response.ArtDetail.CoverImg);
        // this.CoverImgFileList.push({
        //   url: res.data.response.ArtDetail.CoverImg,
        // });
        var imgages = res.data.response.ArtDetail.Images.split(",");
        // console.log(imgages);
        for (let index = 0; index < imgages.length; index++) {
          this.imgList.push(imgages[index]);
          this.imgFileList.push({ url: imgages[index] });
        }
        // console.log(this.imgList);
        // console.log(this.imgFileList);
      }
    },
    async getQueryArtProperty (category) {
      let parm = "?category=" + category;
      // console.log(parm, '')
      const res = await queryArtProperty(parm);
      if (res.success == true) {
        this.categoryList = res.response;
      }
    },
    async queryArtSize () {
      const res = await queryArtSize();
      if (res.success == true) {
        this.artSizeList = res.response;
      }
    },
    async queryArtYear () {
      const res = await queryArtYear();
      if (res.success == true) {
        this.artYearList = res.response;
      }
    },
    async queryArtMaterial () {
      const res = await queryArtMaterial();
      if (res.success == true) {
        this.artMaterialList = res.response;
      }
    },
    async queryArtTheme () {
      const res = await queryArtTheme();
      if (res.success == true) {
        this.artThemeList = res.response;
      }
    },
    async queryArtSkill () {
      const res = await queryArtSkill();
      if (res.success == true) {
        this.artSkillList = res.response;
      }
    },
    async queryArtShape () {
      const res = await queryArtShape();
      if (res.success == true) {
        this.artShapeList = res.response;
      }
    },
    afterRead (file, name) {
      file.file.uploadName = file.action;
      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic (file) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(file); //图片压缩
      formData.append("file", newFile);
      formData.append("progame", "ArtistWork");
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        console.log(file.uploadName, '--', url)
        if (file.uploadName == "coverImg") {
          this.formData.CoverImg = url
        } else {
          // imgFileList
          this.imgList.push(url);
          this.imgFileList.push({ url: url });
        }
        file.serverUrl = url;

      } else {
        this.$message.error(res.msg);
      }
    },
    afterdelete (file) {
      console.log(file);
      console.log(this.imgList);
      //删除数组中的删除元素
      if (file.file == null) {
        var index = this.imgList.indexOf(file.url);
        console.log(index);
        if (index != -1) {
          this.imgList.splice(index, 1);
        } else {
          this.CoverImgList.splice(index, 1);
        }
      } else {

        if (file.file.uploadName == "coverImg") {
          this.CoverImgList.splice(
            this.CoverImgList.indexOf(file.file.serverUrl),
            1
          );
        } else {
          this.imgList.splice(this.imgList.indexOf(file.file.serverUrl), 1);
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.artworkAdd {
  // max-width: 750px;
  margin: 50px auto;
  padding: 50px 150px;
}
/deep/.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
  }
}
</style>